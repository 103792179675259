export type GetNotificationPreferencesResponse = {
	preferences: GetNotificationPreferences
}

export type GetNotificationPreferences = {
	email: EmailNotificationPreferences
	pushNotifications: PushNotificationPreferences
}

export type EmailNotificationPreferences = {
	send: SendNotificationMode
	timeoutBeforeSend: NotificationInterval
}

export type PushNotificationPreferences = {
	send: SendNotificationMode
}

export enum NotificationInterval {
	TEN_MINUTES = "TEN_MINUTES",
	THIRTY_MINUTES = "THIRTY_MINUTES",
	ONE_HOUR = "ONE_HOUR",
}

export enum SendNotificationMode {
	Disabled = "Disabled",
	Enabled = "Enabled",
}

export type SetNotificationSettingsResponse = {
	preferences: GetNotificationPreferences
}

export type SetNotificationSettingsRequest = {
	notificationSettings: SetNotificationSettings
}

export type SetNotificationSettings = {
	email: SetEmailNotificationPreferences
	pushNotifications: SetPushNotificationPreferences
}

export type SetEmailNotificationPreferences = {
	send: SendNotificationMode
	timeoutBeforeSend: NotificationInterval
}

export type SetPushNotificationPreferences = {
	send: SendNotificationMode
}
