import { exhaustive } from "exhaustive"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ModulePopup, ModulePopupProps } from "../../Orders/Components/ModulePopup/ModulePopup"
import style from "./Login.module.css"
import { LoginForm } from "./LoginForm"
import { PasswordResetForm } from "./PasswordResetForm"
import { PasswordResetSent } from "./PasswordResetSent"

type ShowLogin = "login" | "resetPassword" | "resetSent"

export const LoginDialog: FC<ModulePopupProps> = ({ onClose: onCloseExt, ...props }) => {
	const [queryParams, setQueryParams] = useSearchParams()

	const [show, setShow] = useState<ShowLogin>("login")
	const [suggestedReset, setSuggestedReset] = useState<string>("")

	useEffect(() => {
		if (!queryParams.has("login")) {
			queryParams.set("login", "")
			setQueryParams(queryParams)
		}
	}, [])

	function onClose(data?: string) {
		clearQueryParams()
		onCloseExt(data)
	}

	function clearQueryParams(clearRedirectTo?: boolean) {
		if (clearRedirectTo) {
			if (queryParams.has("redirectTo")) {
				queryParams.delete("redirectTo")
			}
		}

		if (queryParams.has("login")) {
			queryParams.delete("login")
			setQueryParams(queryParams)
		}
	}

	const onShowResetPassword = (emailSuggestion?: string) => {
		setSuggestedReset(emailSuggestion ?? "")
		setShow("resetPassword")
	}

	const onResetSent = () => {
		setShow("resetSent")
	}

	const component = exhaustive(show, {
		login: () => <LoginForm onClose={onClose} onShowResetPassword={onShowResetPassword} />,
		resetPassword: () => <PasswordResetForm onClose={onClose} onResetSent={onResetSent} suggested={suggestedReset} />,
		resetSent: () => <PasswordResetSent onClose={onClose} />,
	})

	const onCancel = () => {
		clearQueryParams(true)
		exhaustive(show, {
			login: () => {
				onClose()
			},
			resetPassword: () => {
				setShow("login")
			},
			resetSent: () => {
				onClose()
			},
		})
	}

	return (
		<ModulePopup {...props} onClose={onCancel}>
			<div className={style.outerWrapper}>{component}</div>
		</ModulePopup>
	)
}
