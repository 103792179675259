import { exhaustive } from "exhaustive"
import { HTMLAttributeAnchorTarget, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import type { NavigateOptions } from "react-router/dist/lib/context"
import { sendLocationUpdate, sitesWrapperContainApp } from "../App"
import { useClientRoot } from "../Client/ClientAndUserProvider"
import { getLogger } from "../Logging/getLogger"

const logger = getLogger("Navigator")

/*
 * Types for Use Widget Link Hook
 */
type LinkType = "internal-pages" | "internal-app" | "external"
type NavigatorLinkFormatted = {
	/**
	 * The absolute URL, starting with protocol.
	 */
	absoluteURL: string
	/**
	 * The relative URL, if it's relative, start with `/`.
	 * This is pretty much the same as the path section of the url, only difference is
	 * that we only populate this, if the url is relative in our domain/app/site.
	 */
	relativeURL: string | null
	linkType: LinkType
}

type Navigator = {
	open: (href: string, target?: HTMLAttributeAnchorTarget, replace?: boolean) => void
	formatHref: (href: string) => NavigatorLinkFormatted
}

/**
 * Hook for consolidating all navigation actions.
 * This hook will figure out if a link is external or not and if it should
 * use `navigate` or `window.open`, it is also aware that we might be iframed inside
 * `Skrappy Sites` (or something else in the future).
 */
export function useNavigator(): Navigator {
	const navigate = useNavigate()
	const rootPath = useClientRoot()

	const formatHref = useCallback((href: string) => navigatorFormatHref(rootPath, href), [rootPath])

	const open = useCallback(
		(href: string, target?: HTMLAttributeAnchorTarget, replace?: boolean) =>
			navigatorOpen(formatHref(href), navigate, target, replace),
		[formatHref, navigate],
	)

	return { open, formatHref }
}

/**
 * Parse and format a href and outputs a typed object instead.
 */
function navigatorFormatHref(rootPath: string, href: string): NavigatorLinkFormatted {
	if (href.startsWith("http:") || href.startsWith("https:") || href.startsWith("tel:") || href.startsWith("mailto:")) {
		return { absoluteURL: href, relativeURL: null, linkType: "external" }
	}

	let linkType: LinkType
	let relativeURL: string
	if (href.startsWith("/")) {
		relativeURL = href
		linkType = "internal-app"
	} else {
		relativeURL = `/${rootPath}/${href}`

		if (href.startsWith("pages")) {
			linkType = "internal-pages"
		} else {
			linkType = "internal-app"
		}
	}

	const absoluteUrl = window.location.origin + relativeURL

	return { absoluteURL: absoluteUrl, relativeURL: relativeURL, linkType: linkType }
}

/**
 * Responsible for calling the correct navigation approach depending on the type of link that is used and
 * depending on the `ContainApp` property set on sites. Either a new windows is opened, or a message is sent
 * to the parent frame, so it can change the URL of the windows, or navigate is invoked from `React-Router`.
 *
 * @returns true if the link was opened, and false if not. The return value is primarily for setting `preventDefaults` on events.
 */
function navigatorOpen(
	formatted: NavigatorLinkFormatted,
	navigateFn: (to: string, options?: NavigateOptions) => void,
	target?: HTMLAttributeAnchorTarget,
	replace?: boolean,
) {
	logger.log("Navigator open: ", formatted, target)

	const doNavigate = (formatted: NavigatorLinkFormatted) => {
		if (formatted.relativeURL != null) {
			navigateFn(formatted.relativeURL, { replace: replace })
		} else {
			logger.error("URL should have been relative, but isn't: ", formatted)
			window.open(formatted.absoluteURL, "_self")
		}
	}

	exhaustive(formatted.linkType, {
		"internal-app": () => {
			if (sitesWrapperContainApp != null) {
				exhaustive(sitesWrapperContainApp, {
					ContainWithPrefix: () => {
						doNavigate(formatted)
					},
					OpenInNewByChild: () => {
						window.open(formatted.absoluteURL, "_blank")
					},
					OpenInParent: () => {
						sendLocationUpdate(formatted.absoluteURL, "", "")
					},
				})
			} else {
				doNavigate(formatted)
			}
		},
		"internal-pages": () => {
			doNavigate(formatted)
		},
		external: () => {
			window.open(formatted.absoluteURL, target || "_blank")
		},
	})
}
