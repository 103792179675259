import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { exhaustive } from "exhaustive"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ToggleSwitch } from "../../Orders/Components/Form/ToggleSwitch/ToggleSwitch"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { cls } from "../../Shared/cls"
import consumerInfoStyle from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { onWheelRef } from "../CustomerPortalCustomerDetails/CustomerPortalCustomerDetails"
import myAccountStyle from "../CustomerPortalMyAccount/CustomerPortalMyAccount.module.css"
import {
	GetNotificationPreferences,
	GetNotificationPreferencesResponse,
	NotificationInterval,
	SendNotificationMode,
	SetNotificationSettingsRequest,
	SetNotificationSettingsResponse,
} from "./UserNotificationModel"
import style from "./UserNotificationPreferences.module.css"

type EditNotificationSettingsProps = {
	settings: GetNotificationPreferences
	onClose: () => void
	onDone: (settings: GetNotificationPreferences) => void
}

const EditIntegrationSettingsFormSchema = z.object({
	emailNotificationMode: z.nativeEnum(SendNotificationMode),
	emailNotificationInterval: z.nativeEnum(NotificationInterval),
	pushNotificationMode: z.nativeEnum(SendNotificationMode),
})

type EditIntegrationSettingsFormSchemaType = z.input<typeof EditIntegrationSettingsFormSchema>

const EditNotificationSettings: FC<EditNotificationSettingsProps> = ({ settings, onClose, onDone }) => {
	const client = useClient()

	const [submitting, setSubmitting] = useState(false)
	const [queryParams, setQueryParams] = useSearchParams()

	useEffect(() => {
		if (!queryParams.has("editNotificationSettings")) {
			queryParams.set("editNotificationSettings", "")
			setQueryParams(queryParams)
		}
	}, [])

	const {
		register,
		setValue,
		getValues,
		watch,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm<EditIntegrationSettingsFormSchemaType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(EditIntegrationSettingsFormSchema)(data, context, options)
		},
		defaultValues: {
			emailNotificationMode: settings.email.send,
			emailNotificationInterval: settings.email.timeoutBeforeSend,
			pushNotificationMode: settings.pushNotifications.send,
		},
	})

	async function onSubmit(data: EditIntegrationSettingsFormSchemaType) {
		setSubmitting(true)
		const reqObj: SetNotificationSettingsRequest = {
			notificationSettings: {
				email: {
					send: data.emailNotificationMode,
					timeoutBeforeSend: data.emailNotificationInterval,
				},
				pushNotifications: {
					send: data.pushNotificationMode,
				},
			},
		}

		API.postWithRetries<SetNotificationSettingsResponse>(
			`/customer-portal/notification-settings-v1/${client.identifier}/set`,
			reqObj,
		)
			.then((res) => {
				onDone(res.preferences)
			})
			.catch(() => {})
	}

	return (
		<ModulePopup className={style.modal} onClose={onClose} disableCloseWithEsc={submitting}>
			<div className={style.modalWrapper}>
				<div
					className={consumerInfoStyle.headerText}
					style={{ marginTop: "-15px", marginBottom: "35px", marginRight: "30px" }}>
					Redigera notifikationsinställningar
				</div>
				<form className={style.form} onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
					<div className={orderConfirmStyle.fields}>
						<div className={style.toggleWrapper}>
							<ToggleSwitch
								checked={watch("pushNotificationMode") === SendNotificationMode.Enabled}
								text={"Aktivera push-meddelanden till mobiler och datorer?"}
								textStyles={{
									color: "var(--section-background-text-color)",
									fontSize: "18px",
									userSelect: "none",
								}}
								onChange={() => {
									if (getValues("pushNotificationMode") === SendNotificationMode.Enabled) {
										setValue("pushNotificationMode", SendNotificationMode.Disabled, {
											shouldValidate: true,
											shouldDirty: true,
										})
									} else {
										setValue("pushNotificationMode", SendNotificationMode.Enabled, {
											shouldValidate: true,
											shouldDirty: true,
										})
									}
								}}
							/>
						</div>
						<div className={style.toggleWrapper}>
							<ToggleSwitch
								checked={watch("emailNotificationMode") === SendNotificationMode.Enabled}
								text={"Aktivera notis-påminnelser via e-post?"}
								textStyles={{
									color: "var(--section-background-text-color)",
									fontSize: "18px",
									userSelect: "none",
								}}
								onChange={() => {
									if (getValues("emailNotificationMode") === SendNotificationMode.Enabled) {
										setValue("emailNotificationMode", SendNotificationMode.Disabled, {
											shouldValidate: true,
											shouldDirty: true,
										})
									} else {
										setValue("emailNotificationMode", SendNotificationMode.Enabled, {
											shouldValidate: true,
											shouldDirty: true,
										})
									}
								}}
							/>
						</div>
						<label>
							<SbtH4>Tid mellan e-postutskick</SbtH4>
							<select
								className={orderConfirmStyle.input}
								disabled={submitting}
								{...register("emailNotificationInterval")}>
								<option value={NotificationInterval.TEN_MINUTES}>10 minuter</option>
								<option value={NotificationInterval.THIRTY_MINUTES}>30 minuter</option>
								<option value={NotificationInterval.ONE_HOUR}>60 minuter</option>
							</select>
							<SbtRHFError error={errors.emailNotificationInterval} />
						</label>
					</div>
					<FinalizeButton className={style.finalize} disabled={submitting || !isDirty || !isValid} type="submit">
						Spara inställningar{" "}
						{submitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				</form>
			</div>
		</ModulePopup>
	)
}

type UserNotificationPreferencesProps = {
	className?: string
}

export const UserNotificationPreferences: FC<UserNotificationPreferencesProps> = (
	props: UserNotificationPreferencesProps,
) => {
	const client = useClient()
	const [queryParams, setQueryParams] = useSearchParams()

	const [notificationPreferences, setNotificationPreferences] = useState<GetNotificationPreferences | null | "Loading">(
		"Loading",
	)
	const [edit, setEdit] = useState<boolean>(false)

	useEffect(() => {
		if (queryParams.has("editNotificationSettings")) {
			setEdit(true)
		} else {
			setEdit(false)
		}
	}, [queryParams])

	useEffect(() => {
		if (!client.features.notificationsEnabled) {
			return
		}
		getSettings()
	}, [client.features.notificationsEnabled])

	function getSettings() {
		API.getWithRetries<GetNotificationPreferencesResponse>(
			`/customer-portal/notification-settings-v1/${client.identifier}/get`,
			true,
		)
			.then((response) => {
				setNotificationPreferences(response.preferences)
			})
			.catch(() => {
				setNotificationPreferences(null)
			})
	}

	function notificationIntervalToHumanText(interval: NotificationInterval) {
		return exhaustive(interval, {
			TEN_MINUTES: () => {
				return "10 minuter"
			},
			THIRTY_MINUTES: () => {
				return "30 minuter"
			},
			ONE_HOUR: () => {
				return "60 minuter"
			},
			_: () => "Okänd",
		})
	}

	if (!client.features.notificationsEnabled || notificationPreferences === null) {
		return null
	}

	if (notificationPreferences === "Loading") {
		return (
			<div className={consumerInfoStyle.wrapper} style={{ minHeight: "250px" }}>
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			</div>
		)
	}

	return (
		<>
			{edit && !!notificationPreferences ? (
				<EditNotificationSettings
					settings={notificationPreferences}
					onDone={() => {
						getSettings()
						if (queryParams.has("editNotificationSettings")) {
							queryParams.delete("editNotificationSettings")
							setQueryParams(queryParams)
						}
					}}
					onClose={() => {
						if (queryParams.has("editNotificationSettings")) {
							queryParams.delete("editNotificationSettings")
							setQueryParams(queryParams)
						}
					}}
				/>
			) : null}
			<div className={cls(consumerInfoStyle.wrapper, style.wrapper, props.className)}>
				<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
					<div className={consumerInfoStyle.headerText}>Notifikationsinställningar</div>
					<div>
						<PenIcon
							size={22}
							iconClassName={myAccountStyle.editIcon}
							onClick={() => {
								setEdit(true)
							}}
						/>
					</div>
				</div>
				<div style={{ marginTop: "35px" }}>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Aktivera push-meddelanden till mobiler och datorer?</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={
								notificationPreferences.pushNotifications.send === SendNotificationMode.Enabled
									? "Ja"
									: "Nej"
							}>
							{notificationPreferences.pushNotifications.send === SendNotificationMode.Enabled ? "Ja" : "Nej"}
						</span>
					</div>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Aktivera notis-påminnelser via e-post?</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={notificationPreferences.email.send === SendNotificationMode.Enabled ? "Ja" : "Nej"}>
							{notificationPreferences.email.send === SendNotificationMode.Enabled ? "Ja" : "Nej"}
						</span>
					</div>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Tid mellan e-postutskick</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={notificationIntervalToHumanText(notificationPreferences.email.timeoutBeforeSend)}>
							{notificationIntervalToHumanText(notificationPreferences.email.timeoutBeforeSend)}
						</span>
					</div>
				</div>
			</div>
		</>
	)
}
