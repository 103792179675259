import { isUndefined } from "lodash"
import { convertTextToInitials } from "Orders/Helpers"
import { FC, useEffect, useState } from "react"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { AccentButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { formatPhoneNumberForSweden } from "../../Orders/Components/Form/constants"
import { ChangeUserPassword } from "../ChangeUserPassword/ChangeUserPassword"
import consumerInformationStyle from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { EditUser } from "../EditUser/EditUser"
import { UserNotificationPreferences } from "../UserNotificationSettings/UserNotificationPreferences"
import { GetUser } from "../Users/Users"
import style from "./CustomerPortalMyAccount.module.css"

type CustomerPortalMyAccountProps = {}
export const CustomerPortalMyAccount: FC<CustomerPortalMyAccountProps> = () => {
	const auth = useAuth()
	const [dataLoading, setDataLoading] = useState(false)
	const [data, setData] = useState<GetUser | null>(null)
	const [edit, setEdit] = useState<boolean>(false)
	const [showEditPassword, setShowEditPassword] = useState<boolean>(false)
	const [, setTick] = useState(0)

	useEffect(() => {
		init()
	}, [])

	function init() {
		API.getWithRetries<GetUser>(`/customer-portal/users-v1/myself`, true)
			.then((res) => {
				setData(res)
				setDataLoading(false)
			})
			.catch(() => {
				setDataLoading(false)
			})
	}

	function profilePicRow() {
		if (!data) {
			return null
		}
		return (
			<div className={consumerInformationStyle.profilePicRow} style={{ gap: "10px" }}>
				<div className={consumerInformationStyle.profilePic}>
					{data.profilePictureUrl ? (
						<img
							src={data.profilePictureUrl}
							onClick={() => {
								const url = data.profilePictureUrl
								if (url) {
									window.open(url, "_blank")
								}
							}}
							onError={() => {
								data.profilePictureUrl = undefined
								setTick((tick) => tick + 1)
							}}
							alt="Profilbild"
						/>
					) : (
						<div className={consumerInformationStyle.noProfilePic}>
							{isUndefined(data.firstName) && isUndefined(data.lastName) ? (
								<span>Ej vald</span>
							) : (
								<span>{convertTextToInitials(data.firstName + " " + data.lastName)}</span>
							)}
						</div>
					)}
				</div>
				<span style={{ display: "flex", flexDirection: "column" }}>
					<label
						onClick={() => {
							setEdit(true)
						}}
						className={consumerInformationStyle.uploadProfilePictureButton}>
						<span className={consumerInformationStyle.text}>
							<span className="oneLineClamp">Ladda upp profilbild</span>
						</span>
						<span className={consumerInformationStyle.icon}>+</span>
					</label>
				</span>
			</div>
		)
	}

	function myAccountSection() {
		if (dataLoading || !data) {
			return (
				<div className={style.userDetailsWrapper}>
					<AbsolutCentered>
						<Loader />
					</AbsolutCentered>
				</div>
			)
		}

		return (
			<div className={style.userDetailsWrapper}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div className={consumerInformationStyle.headerText}>Mitt konto</div>
					<div>
						<PenIcon
							size={22}
							iconClassName={style.editIcon}
							onClick={() => {
								setEdit(true)
							}}
						/>
					</div>
				</div>
				{profilePicRow()}

				<div style={{ marginTop: "35px" }} className={consumerInformationStyle.textInputWithLabel}>
					<label>Förnamn</label>
					<span className={consumerInformationStyle.textAsInput}>{data.firstName}</span>
				</div>
				<div className={consumerInformationStyle.textInputWithLabel}>
					<label>Efternamn</label>
					<span className={consumerInformationStyle.textAsInput}>{data.lastName}</span>
				</div>
				<div className={consumerInformationStyle.textInputWithLabel}>
					<label>E-post</label>
					<span className={consumerInformationStyle.textAsInput}>{data.email}</span>
				</div>
				<div className={consumerInformationStyle.textInputWithLabel}>
					<label>Telefonnummer</label>
					<span className={consumerInformationStyle.textAsInput}>
						{formatPhoneNumberForSweden(data.phoneNumber)}
					</span>
				</div>
				<AccentButton
					className={style.changePasswordBtn}
					onClick={() => {
						setShowEditPassword(true)
					}}>
					<span className="oneLineClamp">Ändra lösenord</span>
					<PenIcon size={22} iconClassName={style.changePasswordBtnIcon} />
				</AccentButton>
			</div>
		)
	}

	return (
		<>
			{!dataLoading && data && edit && auth.Me ? (
				<EditUser
					disableEditing={false}
					type={auth.Me.type === "Consumer" ? "Consumer" : "Client"}
					user={data}
					onClose={() => {
						setEdit(false)
					}}
					onDone={() => {
						setEdit(false)
						init()
					}}
					onProfilePicChange={() => {
						init()
					}}
				/>
			) : null}
			{!dataLoading && data && showEditPassword ? (
				<ChangeUserPassword
					onClose={() => {
						setShowEditPassword(false)
					}}
					onDone={() => {
						setShowEditPassword(false)
					}}
				/>
			) : null}
			<div className={style.sectionsWrapper}>
				{myAccountSection()}
				<UserNotificationPreferences className={style.userNotificationPreferences} />
			</div>
		</>
	)
}
