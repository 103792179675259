import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faAngleDown, faAngleUp, faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useJsApiLoader } from "@react-google-maps/api"
import ObjectID from "bson-objectid"
import { exhaustive } from "exhaustive"
import { isArray, isEqual, isNumber, some, sortBy } from "lodash"
import { DateTime } from "luxon"
import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { v4 } from "uuid"
import { useAppIsFocused } from "../../AppIsFocusedContext"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PreferredVATRenderPolicy } from "../../Client/consumerCatalogConfigurationTypes"
import { preferredVATRenderPolicyAsHumanStringShortForm } from "../../Client/ConsumerCatalogContext"
import { OrderActivityRenderMode, OrderReferenceMode } from "../../Client/FeatureTypes"
import { CrossIcon, DatumIcon, KontaktIcon, MarkingIcon, PenIcon, ZonkartaIcon } from "../../Icons/Icon"
import { API } from "../../network/API"
import { ToggleSwitchSlim } from "../../Orders/Components/Form/ToggleSwitchSlim/ToggleSwitchSlim"
import { HorizontalScrollBox } from "../../Orders/Components/HorizontalScrollBox/HorizontalScrollBox"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCheckout/OrderConfirmCheckout.module.css"
import { ProductImage } from "../../Orders/Components/ProductImage/ProductImage"
import { Mbt } from "../../Orders/Components/Text/Mbt/Mbt"
import { SbtH1 } from "../../Orders/Components/Text/SbtH1/SbtH1"
import { convertTextToInitials } from "../../Orders/Helpers"
import { AnonymousOrder } from "../../Orders/OrderCompleted/AnonymousOrderResponse"
import { completedOrderItemDateElementContent } from "../../Orders/OrderCompleted/OrderCompletedSummary/OrderCompletedSummary"
import { productServiceUnitToHumanText } from "../../Orders/OrderContainer/Logic"
import orderContainerStyle from "../../Orders/OrderContainer/OrderContainer.module.css"
import { googleMiniMapOptions, libraries } from "../../Orders/ProjectInputModule/ProjectInputModule"
import { unitFormatter } from "../../Orders/unit-formatter"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { ProductImageType } from "../../ProductDefinitions"
import { cls } from "../../Shared/cls"
import {
	addAddressAndExactDeliveryMarkersToMap,
	GoogleMapComponent,
	initGoogleMapsCustomFullscreenControl,
	maybeSetMapBoundsForExactDelivery,
} from "../../Shared/GoogleMapComponent/GoogleMapComponent"
import {
	GetOrder,
	GetOrderFile,
	getStatusElement,
	OrderLoggedInUserType,
} from "../CustomerPortalOrders/CustomerPortalOrders"
import { GetUploadedFiles, OrderDetailsActivity, OrderMessageActivity } from "./ActivityTab/OrderDetailsActivity"
import { Files, OrderDetailsAttachments, orderFileToSelectedImage } from "./AttachmentsTab/OrderDetailsAttachments"
import style from "./OrderDetails.module.css"
import { OrderPriceDetails } from "./OrderPriceDetails/OrderPriceDetails"

type Props = {
	order: GetOrder
	styles?: CSSProperties
	className?: string
	onTabChange: (tab: SelectedTab) => void
	onUpdate: (order: GetOrder) => void
	onClose: () => void
}

export function contactInformationCreatedOrder(order: GetOrder | AnonymousOrder): JSX.Element | null {
	let contactInformation = null

	if ("contact" in order && order.contact) {
		contactInformation = (
			<span>
				<strong>{order.contact.name}</strong>, {order.contact.phone}
			</span>
		)
	}

	const contactPersons = order.contactPersons
	if (isArray(contactPersons) && contactPersons.length > 0) {
		contactInformation = (
			<span>
				{contactPersons.map((item, index) => {
					return (
						<React.Fragment key={v4()}>
							{index > 0 ? "; " : ""}
							<strong>{item.name}</strong>, {item.phone}
						</React.Fragment>
					)
				})}
			</span>
		)
	}

	return contactInformation
}

export enum SelectedTab {
	Booking = "Booking",
	Activity = "Activity",
	Attachments = "Attachments",
}

type CustomOrderReferenceBase = {
	id: string
}

export type ExternalReference = CustomOrderReferenceBase & {
	type: "ExternalReference"
	value: string
}

export type CoredinationOrderReference = CustomOrderReferenceBase & {
	type: "CoredinationOrderReference"
	uuid: string
}

export type CustomOrderReference = ExternalReference | CoredinationOrderReference

type UpdateCustomOrderReference = {
	reference: CustomOrderReference
}

type SetOrderReferenceResponse = {
	references: CustomOrderReference[]
}

export const OrderDetails: FC<Props> = ({ styles, className, order: extOrder, onTabChange, onUpdate, onClose }) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()
	const isAppFocused = useAppIsFocused()

	const [order, setOrder] = useState<GetOrder>(extOrder)

	const [showProducts, setShowProducts] = useState(true)
	const [showOrderPrices, setShowOrderPrices] = useState(true)
	const [showMiscellaneous, setShowMiscellaneous] = useState(true)
	const [showExternalIntegrations, setShowExternalIntegrations] = useState(true)
	const [, setTick] = useState(0)

	const [queryParams, setQueryParams] = useSearchParams({ tabId: SelectedTab.Booking })

	const [attachments, setAttachments] = useState<GetOrderFile[]>([])
	const [attachmentAmount, setAttachmentAmount] = useState<number>(attachments.length)
	const [activities, setActivities] = useState<OrderMessageActivity[] | null>(null)

	const [updating, setUpdating] = useState(false)
	const [editCustomOrderReference, setEditCustomOrderReference] = useState(false)

	const firstLoad = useRef(true)

	const customOrderReferenceInputRef = useRef<HTMLInputElement | null>(null)

	const [preferredVATRenderPolicy, setPreferredVATRenderPolicy] = useState<PreferredVATRenderPolicy>(() => {
		if (order?.consumerGroupSettings) {
			return exhaustive(order.consumerGroupSettings, "type", {
				Contract: () => {
					return PreferredVATRenderPolicy.IncludeVAT
				},
				Prepay: (it) => {
					return it.preferredVATRenderPolicy
				},
				Postpay: (it) => {
					return it.preferredVATRenderPolicy
				},
			})
		}

		return PreferredVATRenderPolicy.IncludeVAT
	})

	const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	const fetchAttachments = useCallback(() => {
		if (!isAppFocused || !permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read)) {
			return Promise.resolve()
		}

		return API.getWithRetries<GetUploadedFiles>(`/order-ui/order-files-v1/${client.identifier}/${order.id}`, true).then(
			(res) => {
				res.files.reduce<Files>((result, element) => {
					result[element.attachmentId] = orderFileToSelectedImage(element)
					return result
				}, {})
				if (!isEqual(attachments, res.files)) {
					setAttachments(res.files)
				}
			},
			() => {},
		)
	}, [attachments, client.identifier, isAppFocused, order.id, permissions])

	const fetchActivities = useCallback(async () => {
		if (
			!isAppFocused ||
			client.features.orderActivityRenderMode === OrderActivityRenderMode.NoRender ||
			!permissions.isAllowed(PermissionAreaLocation.Order_Activities_Read)
		) {
			return Promise.resolve()
		}

		await fetchAttachments()
		return API.getWithRetries<OrderMessageActivity[]>(
			`/customer-portal/order-activities-v1/${client.identifier}/${order.id}`,
			true,
		).then(
			(res) => {
				const newValues = sortBy(res, "createdAt").reverse()
				if (!isEqual(activities, newValues)) {
					setActivities(newValues)
				}
			},
			() => {},
		)
	}, [
		activities,
		client.features.orderActivityRenderMode,
		client.identifier,
		fetchAttachments,
		isAppFocused,
		order.id,
		permissions,
	])

	useEffect(() => {
		const selectedTab = queryParams.get("tabId") as SelectedTab
		if (selectedTab === SelectedTab.Booking) {
			if (!permissions.isAllowed(PermissionAreaLocation.Orders_Read)) {
				onClose()
				return
			}
		} else if (selectedTab === SelectedTab.Activity) {
			if (
				client.features.orderActivityRenderMode === OrderActivityRenderMode.NoRender ||
				!permissions.isAllowed(PermissionAreaLocation.Order_Activities_Read)
			) {
				queryParams.set("tabId", SelectedTab.Booking)
				setQueryParams(queryParams)
			}
		} else if (selectedTab === SelectedTab.Attachments) {
			if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read)) {
				queryParams.set("tabId", SelectedTab.Booking)
				setQueryParams(queryParams)
			}
		}

		onTabChange(selectedTab)
		return
	}, [queryParams])

	useEffect(() => {
		setAttachmentAmount(attachments.length)
	}, [attachments])

	useEffect(() => {
		if (firstLoad.current && client.features.orderActivityRenderMode !== OrderActivityRenderMode.NoRender) {
			firstLoad.current = false
			return
		}
		fetchAttachments()
	}, [fetchAttachments])

	useEffect(() => {
		fetchActivities()

		const id = setInterval(() => {
			fetchActivities()
		}, 8000)

		return () => {
			clearInterval(id)
		}
	}, [fetchActivities])

	function openCoordinatesInGoogleMaps(coordinates: google.maps.LatLngLiteral) {
		const encodedCoordinates = encodeURIComponent(`${coordinates.lat},${coordinates.lng}`)
		window.open(`https://www.google.com/maps/search/?api=1&query=${encodedCoordinates}`, "_blank")
	}

	function navHeader() {
		return (
			<HorizontalScrollBox
				className={cls(style.navHorizontalScroll, "google-maps-custom-hidden-fullscreen")}
				cellClassName={style.horizontalScrollCell}
				key={`orderDetailsMenuRow`}>
				<div
					className={cls(style.tabSelector, {
						[style.selectedTab]:
							!queryParams.get("tabId") ||
							(queryParams.get("tabId") !== SelectedTab.Activity &&
								queryParams.get("tabId") !== SelectedTab.Attachments),
					})}
					onClick={() => {
						queryParams.set("tabId", SelectedTab.Booking)
						setQueryParams(queryParams)
						onTabChange(SelectedTab.Booking)
					}}>
					Bokning
				</div>
				{client.features.orderActivityRenderMode !== OrderActivityRenderMode.NoRender &&
				permissions.isAllowed(PermissionAreaLocation.Order_Activities_Read) ? (
					<div
						className={cls(style.tabSelector, {
							[style.selectedTab]: queryParams.get("tabId") === SelectedTab.Activity,
						})}
						onClick={() => {
							queryParams.set("tabId", SelectedTab.Activity)
							setQueryParams(queryParams)
							onTabChange(SelectedTab.Activity)
							fetchActivities()
						}}>
						Aktivitet{" "}
						<span
							className={cls(style.tabSelectorAmount, style.notificationColor, {
								[style.zeroAmountCircle]: Object.keys(activities || []).length === 0,
							})}>
							{Object.keys(activities || []).length}
						</span>
					</div>
				) : null}
				{permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read) ? (
					<div
						className={cls(style.tabSelector, {
							[style.selectedTab]: queryParams.get("tabId") === SelectedTab.Attachments,
						})}
						onClick={() => {
							queryParams.set("tabId", SelectedTab.Attachments)
							setQueryParams(queryParams)
							onTabChange(SelectedTab.Attachments)
							fetchAttachments()
						}}>
						Bilagor{" "}
						<span
							className={cls(style.tabSelectorAmount, style.accentColor, {
								[style.zeroAmountCircle]: attachmentAmount === 0,
							})}>
							{attachmentAmount}
						</span>
					</div>
				) : null}
			</HorizontalScrollBox>
		)
	}

	function tabView() {
		switch (queryParams.get("tabId")) {
			case SelectedTab.Booking:
				return orderTab()
			case SelectedTab.Activity:
				return activityTab()
			case SelectedTab.Attachments:
				return attachmentsTab()
			default:
				return orderTab()
		}
	}

	function saveCustomOrderReference() {
		if (!auth.IsLoggedInClient) {
			return
		}

		setUpdating(true)
		const input = customOrderReferenceInputRef.current
		if (input) {
			const reference = order.references.find((x) => x.type === "ExternalReference")
			const req: UpdateCustomOrderReference = {
				reference: {
					type: "ExternalReference",
					id: reference?.id || ObjectID().toHexString(),
					value: input.value || "",
				},
			}
			API.postWithRetries<SetOrderReferenceResponse, UpdateCustomOrderReference>(
				`/customer-portal/orders/client-v1/${client.identifier}/custom-order-reference/${order.id}`,
				req,
				{},
				15,
			).then(
				(ret) => {
					setOrder((order) => {
						order.references = ret.references
						onUpdate(order)
						return order
					})
					setUpdating(false)
					setEditCustomOrderReference(false)
				},
				() => {
					setUpdating(false)
				},
			)
		}
	}

	function customOrderReferenceSection(): JSX.Element | null {
		if (client.features.orderUiOrderReferencesMode !== OrderReferenceMode.ShowExternalReference) {
			return null
		}

		let buttons: JSX.Element = <></>

		if (auth.IsLoggedInClient) {
			if (!editCustomOrderReference) {
				buttons = (
					<div style={{ display: "flex", alignItems: "center" }}>
						<PenIcon
							size={32}
							className={style.editIcon}
							onClick={() => {
								setEditCustomOrderReference(true)
							}}
						/>
					</div>
				)
			} else {
				if (updating) {
					buttons = (
						<div style={{ display: "flex", alignItems: "center" }}>
							<div className={style.loadingIcon}>
								<FontAwesomeIcon icon={faSpinner} spin={true} />
							</div>
						</div>
					)
				} else {
					buttons = (
						<div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
							<CrossIcon
								size={32}
								className={style.cancelIcon}
								onClick={() => {
									setEditCustomOrderReference(false)
								}}
							/>
							<div
								className={style.okIcon}
								onClick={() => {
									saveCustomOrderReference()
								}}>
								<FontAwesomeIcon icon={faCheck} />
							</div>
						</div>
					)
				}
			}
		}

		let section

		if (
			editCustomOrderReference &&
			permissions.isAllowed(PermissionAreaLocation.Client_Order_External_Reference_Edit)
		) {
			section = (
				<div className={style.editCustomOrderReferenceWrapper}>
					<label>
						<input
							ref={customOrderReferenceInputRef}
							readOnly={updating}
							disabled={updating}
							defaultValue={
								(
									order.references.find((x) => x.type === "ExternalReference") as
										| ExternalReference
										| undefined
								)?.value || ""
							}
							className={style.input}
							type="text"
							placeholder="Egen orderreferens"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									saveCustomOrderReference()
								}
							}}
						/>
					</label>
					{buttons}
				</div>
			)
		} else {
			const reference =
				(order.references.find((x) => x.type === "ExternalReference") as ExternalReference | undefined)?.value || ""

			if (reference) {
				section = (
					<div className={cls(style.blockContent, style.spacedRow)}>
						<div style={{ fontSize: "16px", display: "flex", alignItems: "center", height: "32px" }}>
							<strong>{reference}</strong>
						</div>
						{auth.IsLoggedInClient &&
						permissions.isAllowed(PermissionAreaLocation.Client_Order_External_Reference_Edit) ? (
							<div style={{ display: "flex", alignItems: "center" }}>
								<PenIcon
									size={32}
									className={style.editIcon}
									onClick={() => {
										setEditCustomOrderReference(true)
									}}
								/>
							</div>
						) : null}
					</div>
				)
			} else {
				if (auth.IsLoggedInConsumer) {
					return null
				}

				section = (
					<div className={cls(style.blockContent, style.spacedRow)}>
						<div style={{ fontSize: "16px", display: "flex", alignItems: "center", height: "32px" }}>
							<strong>Lägg till referens</strong>
						</div>
						<div
							className={style.pillButton}
							onClick={() => {
								setEditCustomOrderReference(true)
							}}>
							Välj
						</div>
					</div>
				)
			}
		}

		return (
			<div style={{ marginBottom: "15px" }}>
				<div className={cls(style.blockHeader, style.noBackgroundColor)}>
					<span>Extern orderreferens</span>
				</div>
				{section}
			</div>
		)
	}

	function orderTab() {
		return (
			<div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
				{customOrderReferenceSection()}

				<div style={{ marginBottom: "15px" }}>
					<div className={cls(style.blockHeader, style.noBackgroundColor)}>
						<span>Kundinformation</span>
					</div>

					<div className={style.blockContent}>
						<div style={{ fontSize: "16px" }}>
							<strong>{order.customer.name}</strong>
							<br />
							{"orgNumber" in order.customer ? order.customer.orgNumber : order.customer.personNumber}
						</div>
					</div>
				</div>

				<div>
					<div className={cls(style.blockHeader, style.noBackgroundColor)}>
						<span>Leveransinformation</span>
					</div>

					<div className={orderConfirmStyle.addressAndMiniMapWrapper}>
						<div className={orderConfirmStyle.address}>
							<strong>{order.address.street}, </strong>
							{order.address.zipcode ? order.address.zipcode + " " : ""}
							{order.address.city}
						</div>
						{order.marking ? (
							<div
								className={orderConfirmStyle.markingAndContactInfoText}
								style={{
									marginTop: "10px",
									display: "flex",
									gap: "10px",
									alignItems: "center",
								}}>
								<MarkingIcon size={22} />
								<span>
									<strong>{order.marking}</strong>
								</span>
							</div>
						) : null}
						{contactInformationCreatedOrder(order) ? (
							<div
								className={orderConfirmStyle.markingAndContactInfoText}
								style={{
									marginTop: "10px",
									display: "flex",
									gap: "10px",
									alignItems: "center",
								}}>
								<KontaktIcon size={22} />
								<div>{contactInformationCreatedOrder(order)}</div>
							</div>
						) : null}
						{isGoogleMapsLoaded && order.address.coordinates ? (
							<>
								<hr className={orderConfirmStyle.mapAndAddressDelimiter} />
								<GoogleMapComponent
									mapContainerClassName={style.mapContainer}
									center={order.address.coordinates}
									zoom={19}
									onLoad={(map) => {
										initGoogleMapsCustomFullscreenControl(map)
										addAddressAndExactDeliveryMarkersToMap(map, order)
										maybeSetMapBoundsForExactDelivery(map, order)
									}}
									options={googleMiniMapOptions(true, "cooperative")}
								/>
							</>
						) : null}
					</div>

					{order.address.coordinates || order?.execution?.deliveryCoordinates ? (
						<div className={cls(style.blockContent, style.googleMapsButtons)} style={{ marginBottom: "10px" }}>
							{order.address.coordinates ? (
								<div
									className={style.googleMapButton}
									onClick={() => {
										openCoordinatesInGoogleMaps(order.address.coordinates!)
									}}>
									Öppna adress i Google Maps
								</div>
							) : null}
							{order?.execution?.deliveryCoordinates ? (
								<div
									className={style.googleMapButton}
									onClick={() => {
										openCoordinatesInGoogleMaps(order?.execution?.deliveryCoordinates!)
									}}>
									Öppna leveransplats i Google Maps
								</div>
							) : null}
						</div>
					) : null}

					<div style={{ marginBottom: 0 }} className={orderConfirmStyle.orderItemDateAndTimeWrapper}>
						<div className={orderConfirmStyle.orderItemDateAndTime}>
							<DatumIcon className={orderConfirmStyle.dateAndTimeInfoIcon} size={18} />
							{completedOrderItemDateElementContent(order.datetime)}
						</div>
						<div className={orderConfirmStyle.orderItemDateAndTime}>
							<FontAwesomeIcon className={orderConfirmStyle.timeIcon} icon={faClock} />
							<span>{order.datetime.timeExact || order.datetime.timeDescription}</span>
						</div>
					</div>

					<br />

					<div
						className={orderContainerStyle.orderItemZone}
						style={{ backgroundColor: "var(--module-box-color)" }}>
						<div className={orderContainerStyle.orderItemZoneName}>
							{order.transportZoneName ? (
								<>
									Transportzon: <strong>{order.transportZoneName}</strong>
								</>
							) : (
								<strong>Ingen transportzon identifierad</strong>
							)}
						</div>
						<div style={{ display: "grid" }}>
							<ZonkartaIcon
								className={orderContainerStyle.zoneMapIconWrapper}
								iconClassName={orderContainerStyle.zoneMapIcon}
								size={44}
							/>
						</div>
					</div>
				</div>

				<div className={style.transportationMethod}>
					Valt leveranssätt: <strong>{order.products[0]?.transportation?.name || ""}</strong>
				</div>

				<div style={{ marginTop: "35px" }}>
					<div onClick={() => setShowProducts(!showProducts)} className={style.blockHeader}>
						<span>Varor</span>
						<span>
							{showProducts ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
						</span>
					</div>

					{showProducts ? (
						<div>
							{order.products.map((orderProduct) => {
								return (
									<div key={v4()} className={style.productInfoBox}>
										<div>
											<span className={style.productInfoBoxName}>
												<Mbt>{unitFormatter(orderProduct.name)}</Mbt>
											</span>
											<Mbt>
												{orderProduct?.service?.name || orderProduct?.packagingMethod?.name || ""}
											</Mbt>
											{orderProduct.wasteType ? <Mbt>{orderProduct.wasteType.name}</Mbt> : null}
										</div>
										<div style={{ display: "table", marginRight: "15px" }}>
											<span className={style.tableCellWrapper}>
												<span className={style.orderItemProductAmount}>
													{orderProduct.amount}&nbsp;
													{productServiceUnitToHumanText(orderProduct.unit)}
												</span>
											</span>
										</div>
										<div style={{ display: "table" }}>
											<span className={style.tableCellWrapper}>
												{orderProduct.productImages ? (
													"url" in orderProduct.productImages.main ? (
														<div className={style.basketItemProductImage}>
															<img
																style={{
																	maxWidth: "100%",
																	height: "100%",
																	margin: "0 auto",
																	objectFit: "contain",
																}}
																src={orderProduct.productImages.main.url}
																alt="Produktbild"
															/>
														</div>
													) : (
														<ProductImage
															client={client}
															categoryName={orderProduct.category?.name}
															image={
																ProductImageType[orderProduct.productImages.main.typeImage]
															}
															wrapperClassName={style.basketItemProductImage}
														/>
													)
												) : (
													<ProductImage
														client={client}
														categoryName={orderProduct.category?.name}
														image={ProductImageType[orderProduct.typeImage]}
														wrapperClassName={style.basketItemProductImage}
													/>
												)}
											</span>
										</div>
									</div>
								)
							})}
						</div>
					) : null}
				</div>

				{order.orderArticles.length > 0 || some(order.products, (product) => product.productArticles.length > 0) ? (
					<div style={{ marginTop: "35px" }}>
						<div onClick={() => setShowOrderPrices(!showOrderPrices)} className={style.blockHeader}>
							<span>
								Priser{" "}
								<span style={{ fontSize: "14px", marginLeft: "5px" }}>
									({preferredVATRenderPolicyAsHumanStringShortForm(preferredVATRenderPolicy)})
								</span>
							</span>
							<span>
								{showOrderPrices ? (
									<FontAwesomeIcon icon={faAngleUp} />
								) : (
									<FontAwesomeIcon icon={faAngleDown} />
								)}
							</span>
						</div>

						{order.payment && "orderReference" in order.payment && order.payment.orderReference ? (
							<div className={style.blockContent} style={{ marginBottom: "10px" }}>
								<strong>Klarna referensnummer</strong>: {order.payment.orderReference}
							</div>
						) : null}

						{showOrderPrices ? (
							<>
								<div className={style.vatPolicyToggleWrapper}>
									<ToggleSwitchSlim
										checked={preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT}
										onChange={(x) => {
											setPreferredVATRenderPolicy((policy) => {
												if (x.target.checked) {
													return PreferredVATRenderPolicy.IncludeVAT
												}
												return PreferredVATRenderPolicy.ExcludeVAT
											})
										}}
									/>
									<span className={style.vatPolicyToggleText}>Visa priser med moms</span>
								</div>
								<OrderPriceDetails order={order} preferredVATRenderPolicy={preferredVATRenderPolicy} />
							</>
						) : null}
					</div>
				) : null}

				{externalIntegrationsSection()}

				<div style={{ marginTop: "35px" }}>
					<div onClick={() => setShowMiscellaneous(!showMiscellaneous)} className={style.blockHeader}>
						<span>Övrig bokningsinfo</span>
						<span>
							{showMiscellaneous ? (
								<FontAwesomeIcon icon={faAngleUp} />
							) : (
								<FontAwesomeIcon icon={faAngleDown} />
							)}
						</span>
					</div>

					{showMiscellaneous ? (
						<>
							<div style={{ wordBreak: "break-all" }} className={style.blockContent}>
								<div>
									<strong>Beställare</strong>
								</div>
								<div>
									{order.customer.ordererName ? (
										<>
											{order.customer.ordererName}
											<br />
										</>
									) : null}
									{order.customer.email}
									<br />
									{order.customer.phone}
								</div>
							</div>
							{order.loggedInUser &&
							`${order.loggedInUser.firstName || ""} ${order.loggedInUser.lastName || ""}` !==
								order.customer.ordererName &&
							order.loggedInUser.email !== order.customer.email ? (
								<div style={{ wordBreak: "break-all", marginTop: "10px" }} className={style.blockContent}>
									<div>
										<strong>Orderläggare</strong>
									</div>
									<div className={style.loggedInUserWrapper}>
										<div className={style.profilePic}>
											{order.loggedInUser.profilePictureUrl ? (
												<img
													src={order.loggedInUser.profilePictureUrl}
													onError={() => {
														if (order.loggedInUser) {
															order.loggedInUser.profilePictureUrl = undefined
														}
														setTick((tick) => tick + 1)
													}}
													alt="Profilbild"
												/>
											) : (
												<div className={style.noProfilePic}>
													{convertTextToInitials(
														order.loggedInUser.firstName + " " + order.loggedInUser.lastName,
													)}
												</div>
											)}
										</div>
										<div>
											<div>
												{order.loggedInUser.userType === OrderLoggedInUserType.Client
													? client.clientInfo.clientName
													: order.customer.name}
											</div>
											<div>
												<strong>
													{order.loggedInUser.firstName} {order.loggedInUser.lastName}
												</strong>
												, {order.loggedInUser.email}
											</div>
										</div>
									</div>
								</div>
							) : null}
							{order.comment ? (
								<div style={{ hyphens: "auto", marginTop: "10px" }} className={style.blockContent}>
									<div>
										<strong>Kommentar</strong>
									</div>
									<div>{order.comment}</div>
								</div>
							) : null}
							{order.clientComment ? (
								<div style={{ hyphens: "auto", marginTop: "10px" }} className={style.blockContent}>
									<div>
										<strong>Klientkommentar</strong>
									</div>
									<div>{order.clientComment}</div>
								</div>
							) : null}

							{isNumber(order.createdAt) ? (
								<div style={{ wordBreak: "break-all", marginTop: "10px" }}>
									<strong>
										Bokning lagd{" "}
										{DateTime.fromSeconds(order.createdAt)
											.setLocale("sv-SE")
											.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
									</strong>
								</div>
							) : null}
						</>
					) : null}
				</div>
			</div>
		)
	}

	function externalIntegrationsSection() {
		if (!auth.IsLoggedInClient) {
			return null
		}

		const coredinationRef = order.references.find((x) => x.type === "CoredinationOrderReference") || null

		if (!coredinationRef || coredinationRef.type !== "CoredinationOrderReference") {
			return null
		}

		return (
			<div style={{ marginTop: "35px" }}>
				<div onClick={() => setShowExternalIntegrations(!showExternalIntegrations)} className={style.blockHeader}>
					<span>Externa integreringar</span>
					<span>
						{showMiscellaneous ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
					</span>
				</div>

				{showExternalIntegrations ? (
					<div style={{ wordBreak: "break-all", hyphens: "auto" }} className={style.blockContent}>
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<strong>Coredination</strong>
							<div
								className={style.pillButton}
								onClick={() => {
									if (!coredinationRef) {
										return
									}

									window.open(
										`https://web.app.coredination.net/#orders/${coredinationRef.uuid}`,
										"_blank",
									)
								}}>
								Öppna order
							</div>
						</div>
						<div>{}</div>
					</div>
				) : null}
			</div>
		)
	}

	function attachmentsTab() {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read)) {
			return null
		}

		return (
			<OrderDetailsAttachments
				customer={order.customer}
				orderId={order.id}
				files={attachments}
				onAmount={(amount) => setAttachmentAmount(amount)}
			/>
		)
	}

	function activityTab() {
		if (
			client.features.orderActivityRenderMode === OrderActivityRenderMode.NoRender ||
			!permissions.isAllowed(PermissionAreaLocation.Order_Activities_Read)
		) {
			return null
		}

		return (
			<OrderDetailsActivity
				customer={order.customer}
				orderId={order.id}
				activities={activities}
				files={attachments}
				onMessageSend={async (message) => {
					await fetchAttachments()
					setActivities((activities) => {
						if (activities) {
							activities.push(message)
							return sortBy(activities, "createdAt").reverse()
						} else {
							return [message]
						}
					})
				}}
			/>
		)
	}

	return (
		<>
			<div className={cls(style.header, "google-maps-custom-hidden-fullscreen")}>
				<SbtH1 className={style.orderNumber}>#{order.orderNumber}</SbtH1>
				{getStatusElement(order.state, style.statusElement)}
				<span className={style.headerAligner}></span>
			</div>
			{navHeader()}
			<div className={cls(style.wrapper, className)} style={styles}>
				{tabView()}
			</div>
		</>
	)
}
