import React, { useCallback, useContext, useEffect, useState } from "react"

type Props = {
	children: React.ReactNode
}

const AppIsFocusedContext = React.createContext<boolean>(false)

export const AppIsFocusedProvider = ({ children }: Props) => {
	const [appIsFocused, setAppIsFocused] = useState(false)

	const onFocus = useCallback(() => {
		setAppIsFocused(true)
	}, [])

	const onBlur = useCallback(() => {
		setAppIsFocused(false)
	}, [])

	useEffect(() => {
		window.addEventListener("focus", onFocus)
		window.addEventListener("blur", onBlur)

		onFocus()

		return () => {
			window.removeEventListener("focus", onFocus)
			window.removeEventListener("blur", onBlur)
		}
	}, [onBlur, onFocus])

	return <AppIsFocusedContext.Provider value={appIsFocused}>{children}</AppIsFocusedContext.Provider>
}

const useAppIsFocusedContext = () => useContext(AppIsFocusedContext)

export function useAppIsFocused(): boolean {
	return useAppIsFocusedContext()
}
